import React from 'react';
import Layout from '../../containers/gatsby/layout';
import Credibility from '../../containers/sections/Credibility/Credibility';
import ServiceBlurb from '../../containers/services/ServiceBlurb/ServiceBlurb';
import ServiceFAQs from '../../containers/services/ServiceFAQs/ServiceFAQs';
import BookOnlineContainer from '../../containers/sections/BookOnline/BookOnlineContainer';
import ServicesGallery from '../../containers/services/ServicesGallery/ServicesGallery';
import SEO from '../../containers/gatsby/seo';
import ServiceHeroContainer from '../../containers/heroes/ServiceHero/ServiceHeroContainer';

const HouseRemovalsPage = (props) => (
  <Layout overlayHeader={true} location={props.location}>
    <SEO title="Affordable House Removals from £35" keywords={[`house removals london`, `professional home removals`, `man and van move house`, `man and van London`, `house removals`, `cheap house removals`, `same-day house removal service`, ``]} />
    <ServiceHeroContainer
      jobName={'House Removals'}
      title={'House Removals'}
      description={'Moving house? Use JJD to schedule a removal service - it\'s much cheaper than traditional movers'}
      heroImg={require('../../assets/services-heroes/houses.jpg')}
      reviewImage={require('../../assets/avatars/10.png')}
      reviewContent={'Very good serviced, easy to book online, I liked the fact that you can tune it to your own needs. Driver was on time & friendly. I would definitely use it again.'}
      reviewName={'Alfredo Fr'}
    />
    <Credibility />
    <ServiceBlurb
      blurbTitle={'House removal experts at your fingertips'}
      blurb={'Moving house can be a pain, but it doesn\'t have to be that way anymore. JJD has the largest fleet of house removal experts in London ready to help you move into your new home.'}
      blurb2={'We recommend a Luton-sized van (XL) for house removals, especially if you need to transport everything for 3 or more residents.'}
      blurb3={'Choose up to 3 helpers to help you with your move to make it as seamless as possible. Need to move that long awkwardly shaped sofa around some stairs and through the corridor? Let the professionals at JJD help you. We do these moves everyday, we\'ve got you covered.'}
    />
    <ServicesGallery
      gallerySubtitle={'Making moving-day easier and more affordable for everyone'}
      photos={[
        require('../../assets/service-gallery/1.jpg'),
        require('../../assets/service-gallery/2.png'),
        require('../../assets/service-gallery/3.jpg'),
        require('../../assets/service-gallery/4.jpg'),
        require('../../assets/service-gallery/5.jpg'),
        require('../../assets/service-gallery/6.jpg'),
        require('../../assets/service-gallery/8.jpg'),
        require('../../assets/service-gallery/9.png'),
        require('../../assets/service-gallery/10.png'),
        require('../../assets/service-gallery/11.jpg'),
        require('../../assets/service-gallery/12.jpg'),
        require('../../assets/service-gallery/13.jpg'),
        require('../../assets/service-gallery/14.jpg'),
        require('../../assets/service-gallery/15.jpg'),
        require('../../assets/service-gallery/16.jpg'),
        require('../../assets/service-gallery/17.jpg'),
        require('../../assets/service-gallery/18.jpg'),
        require('../../assets/service-gallery/19.jpg'),
        require('../../assets/service-gallery/20.jpg'),
        require('../../assets/service-gallery/21.jpeg'),
        require('../../assets/service-gallery/22.jpeg'),
        require('../../assets/service-gallery/23.jpeg'),
      ]}
    />
    <ServiceFAQs
      faqTitle={'FAQs'}
      faqSubTitle={'Got a question about house removals?'}
      faqs={[
        {
          question: 'How much will it cost?',
          answer: 'House removals generally cost around £60 to £140 depending on your journey length, and how much help you need lifting. Moving from London to Manchester, for example, will cost more than within one area.'
        },
        {
          question: 'When is the best time to do a house move?',
          answer: 'Whenever suits you! Unlike traditional man-and-van companies, JJD uses a pool of available drivers nearest to you - so you can get you moving at any time, and on any day that suits you.'
        },
        {
          question: 'What size van should I choose?',
          answer: 'You get all the space in the van with JJD. We recommend a Luton-sized (XL) van for most house removals, but if you only need to transport a few bulky items, a Long-sized van (Large) or Medium-sized van will do.'
        },
        {
          question: 'Can you do my packing?',
          answer: 'Yes, we can - but it might cost more if you run overtime. We recommend that you get as many things packed up and ready before we arrive to save time.'
        },
        {
          question: 'I have a lot of stairs, is that okay?',
          answer: 'Of course, jut specify how many flights of stairs things need carrying over. If you move things down the stairs before we arrive, just set it to zero stairs - we charge £5 extra per flight of stairs.'
        },
        {
          question: 'I need to drop some stuff at a storage place along the way, can you do that?',
          answer: 'Yes, you can add up to 4 additional stops along the way. This will affect the cost because the journey will be longer.'
        },
        {
          question: 'Are your drivers insured?',
          answer: 'Yes, all of our drivers are insured and vetted by JJD before they are able to join the platform.'
        },
        {
          question: 'Is fuel cost covered?',
          answer: 'Yes, there are no hidden costs at JJD. Fuel, VAT, and van insurance is included in the price. Be aware that many removal companies have extra fees that you don\'t see until later.'
        }
      ]}
    />
    <BookOnlineContainer />
  </Layout>
);

export default HouseRemovalsPage;
